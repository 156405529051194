import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText';
import { content } from '../../../data';
import MobileHeader from "./MobileHeader"
import { useNavigate, useLocation } from 'react-router-dom';
import Button from "../../../components/button/Button"
import { Success } from '../../toast/Success';

const Header = () => {

  const [musicStatus, setMusicState] = useState(true);
  const [activeSection, setActiveSection] = useState("#homepage");
  const audioRef = useRef();
  const { pathname } = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    const audio = audioRef.current;

    const playPromise = audio.play();

    if (playPromise !== undefined) {
      playPromise.then(_ => {
        setMusicState(true);
      }).catch(error => {
        setMusicState(false);
      });
    }

    return () => {
      audio.pause();
    };
  }, []);

  const toggleMusic = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
    setMusicState(!musicStatus);
  };

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    if (pathname === "/") {
      const section = document.querySelector(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
    else {
      navigate("/")
    }
  };

  useEffect(() => {
    function handleScroll() {
      const sections = document.querySelectorAll('section');
      const scrollPosition = window.scrollY + 5;
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActiveSection(`#${section.id}`);
        }
      });
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleToastShow = () => {
    Success({ message: "This Feature is Coming Soon", autoClose: true });
  }

  return (
    <div className='fixed top-0 z-50 w-full max-w-[1440px] mx-auto'>
      <div className='bg-dull-black border-[3px] flex justify-center border-dull-black h-full py-2'>
        <div className='w-[90%] flex items-center justify-between'>
          <div className='flex justify-start w-1/2 lg:w-[30%]'><Link to="/" className='w-[173px]'><img className='w-full h-full' src='/assets/images/footerLogo.png' alt='Logo' /></Link></div>
          <div className='justify-center hidden w-1/3 lg:w-[40%] gap-6 lg:flex'>
            {content?.layout?.header?.map((item, index) => (
              <div key={index}>
                <StyledLgText fontColor={`text-white ${activeSection === item.link ? '!text-orange' : ''} !font-bold hover:text-orange hover:scale-105`}>
                  <NavLink to={item?.link} onClick={(e) => scrollToSection(e, item?.link)}>{item?.title}</NavLink>
                </StyledLgText>
              </div>
            ))}
          </div>
          <div className='flex justify-end items-center w-1/2 gap-4 lg:w-[30%]'>
            <div className='hidden lg:flex w-[40%]'>
              <Button 
              Content="Buy $CHAD"
              target={true} 
              link="https://pancakeswap.finance/swap?outputCurrency=0x43b28B72315CC9c17bcfE47273237b45bCca3EC3" 
              />
            </div>
            <div className='text-white'>
              <img className={`border-2 rounded-full cursor-pointer ${musicStatus ? "border-orange p-[7px]" : " border-white p-[10.5px]"} `} onClick={toggleMusic} src={musicStatus ? "/assets/videos/musicOn.gif" : "/assets/icons/musicOff.png"} alt='Music Icon' />
            </div>
            <div className='flex items-center justify-center lg:hidden'>
              <MobileHeader scrollToSection={scrollToSection} activeSection={activeSection} />
            </div>
          </div>
        </div>
      </div>
      <div className="relative w-full h-[0.75px] bg-gradient-to-r from-dull-black via-orange to-dull-black" />
      <audio ref={audioRef} src="/assets/audios/gigachad.mp3" loop autoPlay />
    </div>
  )
}

export default Header
