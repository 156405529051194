import React, { useLayoutEffect, useRef } from 'react'
import StyledH6Heading from '../../../common/components/styledH6Heading/StyledH6Heading'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {

    const imgBox = useRef();
    const headingBox = useRef();
    const contentRef = useRef()
    const containerBox = useRef()

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            //Animation No.1
            const textLines = contentRef.current.querySelectorAll(".line span");
            gsap.from(textLines, {
                duration: 1,
                autoAlpha: 0,
                y: -200,
                ease: "power2.out",
                skewY: 10,
                stagger: {
                    amount: 1,
                },
                scrollTrigger: {
                    trigger: contentRef.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=20%", // Adjust the start position as needed
                    // markers: true,
                },
            });
            
            //Animation No.2
            gsap.from(headingBox.current, {
                duration: 1,
                opacity: 0,
                x: -50, // move from left to right
                ease: "power2.out",
                scrollTrigger: {
                    trigger: containerBox.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=30%", // Adjust the start position as needed
                    // markers: true,
                },
            });
            
            //Animation No.3
            gsap.from(imgBox.current, {
                duration: 1,
                opacity: 0,
                y: 50, // move from left to right
                ease: "power2.out",
                scrollTrigger: {
                    trigger: containerBox.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=30%", // Adjust the start position as needed
                    // markers: true,
                },
            });

        });
        return () => ctx.revert(); // cleanup
    }, []);

    return (
        <section ref={containerBox} id='about' className='w-[95%] flex flex-col lg:flex-row justify-center'>
            <div ref={imgBox} className='lg:w-[55%]'>
                <img src='/assets/images/about-sec-chad.png' alt='About Section Chad Guy' />
            </div>
            <div className='lg:w-[45%] py-10 lg:py-0 flex items-center justify-center lg:justify-start'>
                <div ref={headingBox} className='flex flex-col gap-2 w-[90%] lg:w-full'>
                    <div className='leading-none'>
                        <StyledH6Heading fontColor="text-white !text-[14px] !font-gilroy-extrabold">Our Story</StyledH6Heading>
                    </div>
                    <div className='leading-none'>
                        <StyledH1Heading regularContent={"About"} regularFontColor={"text-white"} boldColor={"text-orange"} boldContent={"Giga Chad"} />
                    </div>
                    <div ref={contentRef} className=''>
                        <StyledLgText fontColor="text-white line"><span></span>Where the digital frontier meets untapped potential, we, a band of developers, <span>drew inspiration from an unlikely hero: GIGA CHAD. More than a meme, he's a symbol of peak performance and impact</span>, <span>qualities we sought to embed into the very fabric of our creation: the GIGA CHAD token.</span><br /><br /> <span>Our mission is clear: transform the crypto landscape from a solitary pursuit of gain into a collective force for global</span> <span>betterment. GIGA CHAD isn't just our token; it's our pledge to leverage blockchain's might for the common good</span><span>, dedicating a portion of every transaction to lift communities and tackle real-world challenges. In this spirit, GIGA CHAD </span><span>stands as a beacon of hope and action, proving that strength, when channeled for benevolence, can foster transformative change.</span> <span>Join us as we break the mold and redefine what it means to be a titan in the world of cryptocurrency.</span></StyledLgText>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection
