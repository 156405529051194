import React, { useLayoutEffect, useRef } from 'react'
import StyledH5Heading from '../../../common/components/styledH5Heading/StyledH5Heading'
import StyledH2Heading from '../../../common/components/styledH2Heading/StyledH2Heading'
import StyledXlText from '../../../common/components/styledXlText/StyledXlText'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import { content } from '../../../data'

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from 'react-router-dom'
import { Success } from '../../../components/toast/Success'

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const HowToBuySection = () => {

    const headingBox = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            //Animation No.1
            gsap.from(headingBox.current, {
                duration: 1,
                opacity: 0,
                x: -50, // move from left to right
                ease: "power2.out",
                scrollTrigger: {
                    trigger: headingBox.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=30%", // Adjust the start position as needed
                    // markers: true,
                },
            });

            //Animation No.2
            const accordionBoxesLeft = document.querySelectorAll('.boxLeft');

            accordionBoxesLeft.forEach((box, index) => {
                gsap.from(box, {
                    duration: 1,
                    opacity: 0,
                    x: -50,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: box,
                        toggleActions: "play none none reverse",
                        start: "top bottom-=10%",
                        // markers: true
                    },
                    delay: index * 0.15,
                });
            });

            //Animation No.3
            const accordionBoxesRight = document.querySelectorAll('.boxRight');

            accordionBoxesRight.forEach((box, index) => {
                gsap.from(box, {
                    duration: 1,
                    opacity: 0,
                    x: 50,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: box,
                        toggleActions: "play none none reverse",
                        start: "top bottom-=10%",
                        // markers: true
                    },
                    delay: index * 0.15,
                });
            });

        });
        return () => ctx.revert(); // cleanup
    }, []);

    const handleToastShow = () => {
        Success({ message: "This Feature is Coming Soon", autoClose: true });
      }

    return (
        <div className='flex justify-center pt-20 lg:pt-10'>
            <div className='flex flex-col items-center gap-1 w-[90%] lg:w-full justify-center'>
                <div ref={headingBox}>
                    <div className='text-center'>
                        <StyledH2Heading boldColor={"text-orange"} boldContent={"Buy"} regularFontColor={"text-white"} regularContent={"How To"} />
                    </div>
                    <div>
                        <StyledXlText fontColor="text-white">Simple steps to buy Giga Chad Coin</StyledXlText>
                    </div>
                </div>
                <div className='flex flex-col justify-center gap-10 pt-10 lg:flex-row'>
                    <div className='flex flex-col gap-3 lg:w-[40%]'>
                        {content?.howToBuy?.slice(0, 3)?.map((item, index) => (
                            <div key={index} className='boxRight p-4 flex flex-col gap-2 border rounded-md bg-[#FF6D280D] border-orange'>
                                <div><StyledH5Heading fontColor="text-white">Step {index + 1}: {item?.title}</StyledH5Heading></div>
                                <div><StyledLgText fontColor="text-white">{item?.answer}</StyledLgText></div>
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-col gap-3 lg:w-[40%]'>
                        {content?.howToBuy?.slice(3, 6)?.map((item, index) => (
                            <div key={index} className='boxLeft p-4 flex flex-col gap-2 border rounded-md bg-[#FF6D280D] border-orange'>
                                <div><StyledH5Heading fontColor="text-white">Step {index + 4}: {item?.title}</StyledH5Heading></div>
                                <div><StyledLgText fontColor="text-white">
                                    {item?.answer.includes('PancakeSwap') ? (
                                        item?.answer.split('PancakeSwap').map((part, index) => (
                                            index === 0 ? part : (
                                                <React.Fragment key={index}>
                                                    <Link onClick={handleToastShow} className="underline text-orange">
                                                        PancakeSwap
                                                    </Link>
                                                    {part}
                                                </React.Fragment>
                                            )
                                        ))
                                    ) : (
                                        item?.answer
                                    )}
                                </StyledLgText></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowToBuySection
