import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { usePath } from "../../../context/PathContext";
import StyledXlText from "../../../common/components/styledXlText/StyledXlText";
import { content } from "../../../data";
import StyledLgText from "../../../common/components/styledLgText/StyledLgText";
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import Button from "../../button/Button";
import { Success } from "../../toast/Success";

const MobileHeader = ({ scrollToSection, activeSection }) => {
  const [navShow, setNavShow] = useState(false);
  const { path } = usePath();

  useEffect(() => {
    setNavShow(false);

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [path]);

  const onToggleNav = () => {
    setNavShow((status) => {
      if (status) {
        document.body.style.overflow = "auto";
      } else {
        // Prevent scrolling
        document.body.style.overflow = "hidden";
      }
      return !status;
    });
  };

  const handleMobileNav = (e, link) => {
    onToggleNav()
    scrollToSection(e, link)
  }

  const handleToastShow = () => {
    Success({ message: "This Feature is Coming Soon", autoClose: true });
  }

  return (
    <div className="lg:hidden">
      <button
        type="button"
        className="mt-2 text-xl leading-none bg-transparent rounded-full outline-none cursor-pointer focus:outline-none"
        aria-label="Toggle Menu"
        onClick={onToggleNav}
      >
        <img src="/assets/icons/hamburger.png" alt="Hamburger" />
      </button>
      <div
        className={`fixed top-0 left-0 z-10 h-full w-[100vw] transform bg-dull-black ${navShow ? "translate-x-[0%]" : "-translate-x-full"
          }`}
        style={{ transition: "all 0.5s cubic-bezier(.68,-0.55,.27,1.55)" }}
      >
        <div className="flex justify-end">
          <button
            type="button"
            className="z-10 w-4 h-4 mt-4 mr-4 rounded"
            aria-label="Toggle Menu"
            onClick={onToggleNav}
          >
            <img src="/assets/icons/crossIcon.png" alt="Cross Icon" />
          </button>
        </div>
        <nav className="fixed top-0 left-0 h-full w-[100vw]">
          <div className="flex flex-col justify-around h-full gap-4">
            <div className='flex justify-center w-full mt-6'>
              <Link to="/" className="flex justify-center w-[60%]">
                <img className='h-full ' src='/assets/images/footerLogo.png' alt='Logo' />
              </Link>
            </div>
            <div className="flex flex-col gap-4 text-center">
              {content?.layout?.header?.map((item, index) => (
                <Link to={item?.link} onClick={(e) => handleMobileNav(e, item?.link)} className="uppercase" key={index}>
                  <StyledXlText fontColor={`text-white hover:text-orange ${activeSection === item.link ? '!text-orange' : ''}`}>{item.title}</StyledXlText>
                </Link>
              ))}
            </div>
            <div className='w-[60%] self-center' onClick={() => {
              onToggleNav()
            }}>
              <Button target={true} 
              link="https://pancakeswap.finance/swap?outputCurrency=0x43b28B72315CC9c17bcfE47273237b45bCca3EC3"  Content="Buy $CHAD" />
            </div>
            <div className="flex flex-col items-center gap-6">
              <div className="flex flex-col gap-3">
                <div className="text-center">
                  <StyledLgText fontColor="text-white">Find us on</StyledLgText>
                </div>
                <div className="flex gap-3">
                  {content?.layout?.footer?.map((item, index) => (
                    <Link to={item.link} target='__blank' className="uppercase hover:scale-105" key={index}>
                      <img src={item?.imgURL} alt="Social Icons" />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-center">
                  <StyledMdText fontColor="text-white underline hover:text-orange">
                    <Link to="mailto:info@gigachadtoken.com">info@gigachadtoken.com</Link>
                  </StyledMdText>
                </div>
                <div className='flex gap-3'>
                  {/*<div><StyledMdText fontColor={`text-white hover:text-orange hover:underline ${path === "/privacy-policy" ? '!text-orange' : ''}`}><Link to="/privacy-policy">Privacy Policy</Link></StyledMdText></div>
                  <div><StyledMdText fontColor={`text-white hover:text-orange hover:underline ${path === "/terms-and-conditions" ? '!text-orange' : ''}`}><Link to="/terms-and-conditions">Terms & Conditions</Link></StyledMdText></div>*/}
                  <StyledMdText fontColor="text-white">Check on <Link target="__blank" to="https://www.dextools.io/app/en/bnb/pair-explorer/0xf838581b751cd76a8b745c21fbc6860ade08eacb?t=1709326419367" className='text-orange'>Dex Screener</Link></StyledMdText>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default MobileHeader;
