import React from "react";
import ChartTokenomics from "react-apexcharts";

const TokenomicsChart = () => {

  const chartOptions = {
    legend: {
      show: false,
    },
    colors: ['#FAD73A', '#BBB', '#E9B824', '#94591eff', '#FD8D14'],
    labels: ['Liquidity', 'Charities', 'Marketing', "CEX's", 'Team'],
    chart: {
      type: "pie",
    },
    stroke: {
      colors: ['#0c0c0c'],
      width: 0,
    },
  };

  const chartSeries = [70, 9, 8, 8, 5];

  return (
    <div id="chart" className="w-full h-full py-10 lg:py-0">
      <ChartTokenomics
        options={chartOptions}
        series={chartSeries}
        type="pie"
      />
    </div>
  );
};

export default TokenomicsChart;
