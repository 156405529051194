import React, { useState } from 'react'
import StyledH2Heading from '../../../common/components/styledH2Heading/StyledH2Heading'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import StyledH4Heading from '../../../common/components/styledH4Heading/StyledH4Heading'
import StyledH5Heading from '../../../common/components/styledH5Heading/StyledH5Heading'
import { content } from "../../../data"

const RoadmapSection = () => {

  const [hoverDataBox1, setHoverDataBox1] = useState(content.roadmap[2024][0])
  const [hoverDataBox2, setHoverDataBox2] = useState(content.roadmap[2025][0])

  return (
    <section id='roadmap' className='flex justify-center py-20 lg:py-0'>
      <div className='flex flex-col items-center gap-3 w-[90%] justify-center'>
        <div className='lg:text-center'>
          <StyledH2Heading boldColor={"text-orange"} boldContent={"Towards Success"} regularFontColor={"text-white"} regularContent={"RoadMap"} />
        </div>
        <div className='lg:w-[50%] lg:text-center'>
          <StyledLgText fontColor="text-white">The journey of GIGA CHAD Coin is paved with milestones setto drive our mission forward, innovate within the crypto space, and createlasting impact. Here's a glimpse into our past achievements and the path we'recharting for the future.</StyledLgText>
        </div>
        <div className='flex w-full mt-3 border-l-2 border-r-2 border-white border-dotted lg:mt-10 border-opacity-40'>
          <div className='w-[50%] items-center border-r-2 border-white border-dotted border-opacity-40 hidden lg:flex flex-col gap-10'>
            {Object.keys(content?.roadmap)?.map(year => (
              <div className='w-[80%] flex flex-col gap-3' key={year}>
                <div>
                  <StyledH4Heading fontColor="text-orange">{year}</StyledH4Heading>
                </div>
                <div className='flex flex-wrap items-start justify-center gap-3'>
                  {content?.roadmap[year]?.map((item, index) => (
                    <div onMouseEnter={() => year === "2024" ? setHoverDataBox1(item) : setHoverDataBox2(item)} key={index} className='boxLeft hover:bg-orange hover:bg-opacity-30 w-[45%] justify-center p-4 flex gap-2 border rounded-md bg-[#FF6D280D] border-orange'>
                      <div><StyledH5Heading fontColor="text-white text-center">{item.title}</StyledH5Heading></div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div className='w-[50%] hidden lg:flex flex-col items-center'>
            {Object.keys(content?.roadmap)?.map((year, index) => (
              <div key={year} className={`h-[55%] flex ${index === 0 ? "items-center" : "items-end"} w-[90%]`}>
                <div className='boxRight w-full p-4 flex flex-col gap-2 border rounded-md bg-[#FF6D280D] border-orange'>
                  <div><StyledH5Heading fontColor="text-white">{index === 0 ? hoverDataBox1.title : hoverDataBox2.title}</StyledH5Heading></div>
                  <div><StyledLgText fontColor="text-white">{index === 0 ? hoverDataBox1.answer : hoverDataBox2.answer}</StyledLgText></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='flex flex-col w-full gap-10 lg:hidden'>
          {Object.keys(content?.roadmap)?.map(year => (
            <div className='flex flex-col gap-3' key={year}>
              <div>
                <StyledH4Heading fontColor="text-orange">{year}</StyledH4Heading>
              </div>
              <div className='flex flex-wrap gap-3'>
                {content?.roadmap[year]?.map((item, index) => (
                  <div key={index} className='boxLeft hover:bg-orange hover:bg-opacity-30 w-full flex-col justify-center p-4 flex gap-2 border rounded-md bg-[#FF6D280D] border-orange'>
                    <div><StyledH5Heading fontColor="text-white">{item.title}</StyledH5Heading></div>
                    <div><StyledLgText fontColor="text-white">{item.answer}</StyledLgText></div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default RoadmapSection
