import React, { useLayoutEffect, useRef } from 'react'
import { content } from '../../../data'
import StyledH2Heading from '../../../common/components/styledH2Heading/StyledH2Heading'
import StyledH4Heading from '../../../common/components/styledH4Heading/StyledH4Heading'
import { Link } from 'react-router-dom'
import StyledMdText from '../../../common/components/styledMdText/StyledMdText'

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const JoinCommunitySection = () => {

  const contentBox = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

        //Animation No.1
        gsap.from(contentBox.current, {
            duration: 1,
            opacity: 0,
            x: -50, // move from left to right
            ease: "power2.out",
            scrollTrigger: {
                trigger: contentBox.current,
                toggleActions: "play none none reverse",
                start: "top bottom-=30%", // Adjust the start position as needed
                // markers: true,
            },
        });

    });
    return () => ctx.revert(); // cleanup
}, []);

  return (
    <section id='contact' className='flex justify-center' style={{
      background: `url("/assets/images/join-community-bg.png")`,
      backgroundSize: "cover"
    }}>
      <div className='w-[90%] lg:w-[85%] h-full py-10 lg:py-24 flex lg:flex-row flex-col lg:items-center'>
        <div className='flex self-center w-[80%] md:w-[45%] flex-wrap'>
          {
            content.community.map((item, index) => (
              <div key={index} className={`${index === 1 ? "w-[45%] md:w-[35%]" : "w-[50%] md:w-[40%]"} p-2`}>
                <img src={item.imgUrl} className='w-full duration-500 hover:scale-110 hover:opacity-90' alt="Community section images" />
              </div>
            ))
          }
        </div>
        <div ref={contentBox} className='lg:w-[55%] lg:mt-20 h-[50%] flex flex-col justify-between'>
          <div className='flex flex-col gap-2 py-10 lg:py-0'>
            <div className='leading-none'>
              <StyledH2Heading boldColor="text-orange" regularFontColor="text-white" boldContent="Community Of Chads" regularContent="Join The" />
            </div>
            <div className='leading-none'>
              <StyledH4Heading fontColor="text-white">Follow us for latest updates</StyledH4Heading>
            </div>
            <div className="flex gap-3 py-6">
              {content?.layout?.footer?.map((item, index) => (
                <Link to={item.link} target='__blank' className="uppercase duration-500 cursor-pointer hover:scale-110" key={index}>
                  <img src={item?.imgURL} alt="Social Icons" />
                </Link>
              ))}
            </div>
          </div>
          <div className="lg:w-[80%]">
                <StyledMdText fontColor="text-orange">We are a growing community that love our mission! Our X Twitter community is filled with members 24/7 that would love to help you. Do not trust random messages of people claming to be “support” or ever give out your wallet info to anyone.</StyledMdText>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JoinCommunitySection
