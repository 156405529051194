import './App.css';
import Header from './components/layout/header/Header';
import Router from './router';
import { usePath } from './context/PathContext';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Footer from './components/layout/footer/Footer';
import { useEffect, useState } from 'react';
import Loader from './components/loader/Loader';

function App() {

  const { path } = usePath();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(loadingTimeout);

  }, [path]);

  return (
    <>
      {isLoading === true ?
        (
          <Loader />
        ) :
        <div className='w-full max-w-[1440px] mx-auto'>
          <Header />
          <style jsx="true">{`
    ::-webkit-scrollbar {
          width: 5px !important;
          background-color: transparent;
        }
        
        ::-webkit-scrollbar-thumb {
          background-color: #fd8d14;
        }
      `}</style>
          <Router />
          <ScrollToTop />
          <Footer />
        </div>
      }
    </>
  );
}

export default App;
