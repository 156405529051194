import React from 'react'
import MarqueeComponent from '../../../components/marqueeComponent/MarqueeComponent'

const MarqueeSection = () => {
  return (
    <div className='relative'>
      <MarqueeComponent dir="right" color="outlined-orange-text" borderColor="border-white" rotate="rotate-[-15deg] md:rotate-[-7.05deg]" />
      <MarqueeComponent dir="left" color="text-white" borderColor="border-orange absolute top-0 lg:top-14" rotate="rotate-[10deg] md:rotate-[5deg]" />
    </div>
  )
}

export default MarqueeSection
