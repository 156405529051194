import React, { useLayoutEffect, useRef } from 'react'
import StyledH6Heading from '../../../common/components/styledH6Heading/StyledH6Heading'
import StyledH2Heading from '../../../common/components/styledH2Heading/StyledH2Heading'
import AccordionBox from '../../../components/accordionBox/AccordionBox'

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const FaqSection = () => {

    const headingBox = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            //Animation No.1
            gsap.from(headingBox.current, {
                duration: 1,
                opacity: 0,
                x: -50, // move from left to right
                ease: "power2.out",
                scrollTrigger: {
                    trigger: headingBox.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=15%", // Adjust the start position as needed
                    // markers: true,
                },
            });

        });
        return () => ctx.revert(); // cleanup
    }, []);

    return (
        <section id='faqs' className='pt-20 bg-faq-section'>
            <div className='flex flex-col items-center justify-center lg:flex-row'>
                <div className='lg:h-[90vh] w-[90%] flex justify-center pb-20 lg:pb-0'>
                    <div className='flex flex-col gap-5 lg:w-[60%]'>
                        <div ref={headingBox}>
                            <div>
                                <StyledH6Heading fontColor="text-white !text-[14px]">FAQ's</StyledH6Heading>
                            </div>
                            <div className='leading-none'>
                                <StyledH2Heading boldColor={"text-orange"} regularContent={"Frequently Asked"} boldContent={"Questions"} regularFontColor={"text-white"} />
                            </div>
                        </div>
                        <div>
                            <AccordionBox />
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:hidden'>
                <img src='/assets/images/faq-chad-boy.png' alt='Chad Boy' />
            </div>
        </section>
    )
}

export default FaqSection
