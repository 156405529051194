import React from "react";
import Marquee from "react-fast-marquee";
import { content } from "../../data";
import StyledMarqueeText from "../../common/components/styledMarqueeText/StyledMarqueeText";

const MarqueeComponent = ({ borderColor, rotate, color, dir }) => {
    return (
        <div className={`w-full bg-black border-[5px] ${rotate} ${borderColor} border-l-0 border-r-0`}>
            <Marquee speed={"100"} direction={dir}>
                {content.marquee.map((item, index) => (
                    <div key={index} className="py-4 mr-32">
                        <StyledMarqueeText fontColor={color}>{item.content}</StyledMarqueeText>
                    </div>
                ))}
            </Marquee>
        </div>
    );
};

export default MarqueeComponent;
