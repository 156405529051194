import React from 'react';
import StyledH6Heading from '../../common/components/styledH6Heading/StyledH6Heading';
import './AnimatedLink.css'; 
import { Link } from 'react-router-dom';

const AnimatedLink = ({ content, onClick, href }) => {
  return (
    <Link to={href} target={href ? "_blank" : ""} onClick={onClick} className='flex items-center justify-center gap-3 text-white hover-trigger hover:scale-95 hover:duration-200 hover:text-orange'>
      <StyledH6Heading children={content} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        className="animated-svg" 
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.51788 0.238643L13.7588 5.42386C14.0804 5.74206 14.0804 6.25794 13.7588 6.57614L8.51788 11.7614C8.19628 12.0795 7.67485 12.0795 7.35324 11.7614C7.03163 11.4432 7.03163 10.9273 7.35324 10.6091L11.1883 6.81478L0.823529 6.81478C0.368707 6.81478 -4.38512e-08 6.44999 -4.07919e-08 6C-3.77326e-08 5.55001 0.368707 5.18522 0.823529 5.18522L11.1883 5.18522L7.35324 1.39091C7.03163 1.07272 7.03163 0.556834 7.35324 0.238643C7.67485 -0.0795477 8.19628 -0.0795477 8.51788 0.238643Z"
          fill="white"
          className="svg-path" 
        />
      </svg>
    </Link>
  );
};

export default AnimatedLink;
