import React, { useLayoutEffect, useState } from "react";
// Files Import
import StyledH6Heading from "../../common/components/styledH6Heading/StyledH6Heading";
import StyledMdText from "../../common/components/styledMdText/StyledMdText";
import { content } from "../../data"

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

export default function AccordionBox() {
    const [activeElement, setActiveElement] = useState(0);
    const handleClick = (index) => {
        setActiveElement(activeElement === index ? null : index);
    };

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            //Animation No.1
            const accordionBoxes = document.querySelectorAll('.accordion-box');

            accordionBoxes.forEach((box, index) => {
                gsap.from(box, {
                    duration: 1,
                    opacity: 0,
                    x: -50,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: box,
                        toggleActions: "play none none reverse",
                        start: "top bottom-=25%",
                        // markers: true
                    },
                    delay: index * 0.25,
                });
            });

        });
        return () => ctx.revert(); // cleanup
    }, []);

    return (
        <div className="w-full text-white">
            {content.faq.map((item, index) => (
                <div
                    key={index}
                    onClick={() => handleClick(index)}
                    className={`flex accordion-box transition-all duration-100 cursor-pointer px-6 py-4 flex-col mb-2 border rounded-md bg-[#FF6D280D] ${activeElement === index ? "border-orange gap-3" : "gap-0"}`}
                >
                    <div>
                        <button className="w-full text-left focus:outline-none">
                            <div
                                className={`flex justify-between items-center `}
                            >
                                <StyledH6Heading>{item.question}</StyledH6Heading>
                                {/* Arrow  icon */}
                                {
                                    activeElement === index ? (
                                        <img src="/assets/icons/minus.png" alt="Minus Icon" />
                                    ) : (
                                        <img src="/assets/icons/plus.png" alt="Minus Icon" />
                                    )
                                }
                            </div>
                        </button>
                    </div>
                    <div
                        style={{
                            maxHeight: activeElement === index ? "1000px" : "0",
                            height: activeElement === index ? "max-content" : "0",
                            overflow: "hidden",
                            transition: "max-height 2.7s ease",
                        }}
                    >
                        <StyledMdText fontColor={"text-white"} children={item.answer} />
                    </div>
                </div>
            ))}
        </div>
    );
}