import React from 'react'
import StyledH5Heading from '../../common/components/styledH5Heading/StyledH5Heading'
import StyledMdText from '../../common/components/styledMdText/StyledMdText'

const ProtocolBox = ({content, child}) => {
  return (
    <div className='w-full gap-3 flex flex-col border hover:bg-[#FF6D2808] hover:text-orange border-white text-white hover:border-orange rounded-md justify-center items-start p-3'>
        <StyledH5Heading  children={content} />
        <StyledMdText
            fontColor={"text-white"}
            children={ child }
          />
    </div>
  )
}

export default ProtocolBox