import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({ Content, link, target }) => {

  return (
    <Link to={link} target={target ? "_blank" : ""} rel={target ? "noopener noreferrer" : ""} className="w-full h-full py-5 button-gradient rounded-md justify-center items-center gap-2.5 inline-flex">
      <button className="text-dull-black text-base font-bold font-inter leading-[14.40px]">{Content}</button>
    </Link>
  )
}

export default Button
