import React from 'react'
import { Link } from 'react-router-dom';

/* Components Imports */
import StyledMdText from '../../../common/components/styledMdText/StyledMdText'
import LogoSection from '../../../sections/LogoSection/LogoSection';
import { Success } from '../../toast/Success';
// import { usePath } from '../../../context/PathContext';

const Footer = () => {

  const currentYear = new Date().getFullYear();
  // const { path } = usePath();

  const handleToastShow = () => {
    Success({ message: "This Feature is Coming Soon", autoClose: true });
  }

  return (
    <>
      {/*Main Footer ( Links )*/}
      <div className='bg-black border-[3px] flex justify-center border-black py-8 border-t-orange border-b-orange'>
        <div className='w-[90%] lg:w-[85%] flex flex-col gap-5 lg:gap-0 lg:flex-row items-center justify-between'>
          <div className='flex lg:justify-start lg:w-[30%]'>
            <StyledMdText fontColor="text-white underline hover:text-orange">
              <Link to="mailto:info@gigachadtoken.com">info@gigachadtoken.com</Link>
            </StyledMdText>
          </div>
          <div className='flex lg:justify-center text-center lg:w-[40%] order-3 lg:order-2'>
            <StyledMdText fontColor="text-orange">Copyright &#169; {currentYear} Giga Chad Coin (Giga). All Rights Reserved.</StyledMdText>
          </div>
          <div className='flex lg:justify-end text-center lg:w-[30%] gap-4 text-white order-2 lg:order-3'>
            {/*<div><StyledMdText fontColor={`text-white hover:text-orange hover:underline ${path === "/privacy-policy" ? '!text-orange' : ''}`}><Link to="/privacy-policy">Privacy Policy</Link></StyledMdText></div>
            <div><StyledMdText fontColor={`text-white hover:text-orange hover:underline ${path === "/terms-and-conditions" ? '!text-orange' : ''}`}><Link to="/terms-and-conditions">Terms & Conditions</Link></StyledMdText></div>*/}
            <StyledMdText fontColor="text-white">Check on <Link target="__blank" to="https://www.dextools.io/app/en/bnb/pair-explorer/0xf838581b751cd76a8b745c21fbc6860ade08eacb?t=1709326419367" className='text-orange'>Dex Screener</Link></StyledMdText>
          </div>
        </div>
      </div>

      {/*Logo Section*/}
      <LogoSection />
    </>
  )
}

export default Footer
