import React, { useLayoutEffect, useRef, useState } from "react";
import StyledH2Heading from "../../../common/components/styledH2Heading/StyledH2Heading";
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import Button from "../../../components/button/Button";
import TokenomicsChart from "../../../components/tokenomicsChart/TokenomicsChart";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PdfViewer from "../../../components/pdfViewer/PdfViewer";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);


const Tokenomics = () => {

  const contentBox = useRef();
  const [iframeModalOpen, setIframeModalOpen] = useState(false);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(contentBox.current, {
        duration: 1,
        opacity: 0,
        x: -50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: contentBox.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=20%", // Adjust the start position as needed
          // markers: true,
        },
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  const handleViewOnline = () => {
    setIframeModalOpen(true); // Open the iframe modal
  };

  const closeIframeModal = () => {
    setIframeModalOpen(false); // Close the iframe modal
  };

  return (
    <section id="tokenomics" className="flex items-center justify-center w-full h-full py-[25%] lg:py-[15%] ">
      {iframeModalOpen && (
        <PdfViewer
          closeModal={closeIframeModal}
          iframeUrl="/assets/document/Giga CHAD WhitePaper.pdf"
        />
      )}
      <div className="w-[95%] lg:w-[90%] gap-5 lg:gap-0 flex-col lg:flex-row flex justify-center lg:justify-between items-center">
        <div ref={contentBox} className="flex flex-col gap-3 w-[90%] items-start justify-center lg:w-[40%]">
          <StyledH2Heading
            regularFontColor={"text-white"}
            regularContent={"OUR"}
            boldColor={"text-orange"}
            boldContent={"TOKENOMICS"}
          />
          <StyledMdText
            fontColor={"text-white"}
            children={
              "Our launch was equitable, without an ICO or IDO, allocating 70% of the tokens to PancakeSwap for liquidity. We reserved 5% for the team, 9% for charities, 8% for marketing, and 8% for centralized exchanges (CEXs). Tokens not utilized as designated will be burned, ensuring transparency and integrity in our allocation strategy."
            }
          />

          <div className="pt-4 w-[50%]" onClick={handleViewOnline}>
            <Button Content={"Giga White Paper"} />
          </div>
        </div>

        <div className=" w-[100%] md:w-[60%] lg:w-[45%] lg:flex">
          <TokenomicsChart />
          <div className="flex flex-row flex-wrap items-start justify-center gap-5 lg:justify-start lg:flex-col">
            <div className="flex justify-center gap-2 lg:gap-3 item-center">
              <svg
                className="flex self-center"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="7" fill="#FAD73A" />
              </svg>
              <p className="text-sm text-white lg:text-lg">Liquidity</p>
            </div>
            <div className="flex justify-center gap-2 lg:gap-3 item-center">
              <svg
                className="flex self-center"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="7" fill="#BBB" />
              </svg>
              <p className="text-sm text-white lg:text-lg">Charities</p>
            </div>
            <div className="flex justify-center gap-2 lg:gap-3 item-center">
              <svg
                className="flex self-center"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="7" fill="#E9B824" />
              </svg>
              <p className="text-sm text-white lg:text-lg">Marketing</p>
            </div>
            <div className="flex justify-center gap-2 lg:gap-3 item-center">
              <svg
                className="flex self-center"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="7" fill="#94591eff" />
              </svg>
              <p className="text-sm text-white lg:text-lg">CEX’s</p>
            </div>
            <div className="flex justify-center gap-2 lg:gap-3 item-center">
              <svg
                className="flex self-center"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7" cy="7" r="7" fill="#FD8D14" />
              </svg>
              <p className="text-sm text-white lg:text-lg">Team</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
