import React from 'react'

const LogoSection = () => {
    return (
        <div className='flex justify-center h-full py-5 bg-dull-black'>
            <img className='md:w-[285px] lg:h-[70px] w-[50%] h-[50%]' src='/assets/images/footerLogo.png' alt='Footer Logo' />
        </div>
    )
}

export default LogoSection
