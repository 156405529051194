import React from 'react'
import LandingSection from '../../sections/Home/LandingSection/LandingSection'
import AboutSection from '../../sections/Home/AboutSection/AboutSection'
import RevealSection from '../../sections/Home/RevealSection/RevealSection'
import Tokenomics from '../../sections/Home/Tokenomics/Tokenomics'
import JoinCommunitySection from '../../sections/Home/JoinCommunitySection/JoinCommunitySection'
import MarqueeSection from '../../sections/Home/MarqueeSection/MarqueeSection'
import FaqSection from '../../sections/Home/FaqSection/FaqSection'
import CoinProtocolSection from '../../sections/Home/CoinProtocolSection/CoinProtocolSection'
import HowToBuySection from '../../sections/Home/HowToBuySection/HowToBuySection'
import RoadmapSection from '../../sections/Home/RoadmapSection/RoadmapSection'

const HomePage = () => {
  return (
    <div>
      <LandingSection />
      <AboutSection />
      <RevealSection />
      <div style={{
        background: `url("/assets/images/bg-image-round.png")`,
        backgroundPositionX: "left",
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain"
      }}>
        <CoinProtocolSection />
        <Tokenomics />
      </div>
      <RoadmapSection />
      <div className='h-[50vh] md:h-[70vh] xl:h-[90vh] flex items-center'>
        <MarqueeSection />
      </div>
      <HowToBuySection />
      <FaqSection />
      <JoinCommunitySection />
    </div>
  )
}

export default HomePage
