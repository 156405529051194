import React, { useLayoutEffect, useRef } from 'react'
import StyledH6Heading from '../../../common/components/styledH6Heading/StyledH6Heading'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import StyledLgText from '../../../common/components/styledLgText/StyledLgText'
import StyledMdText from '../../../common/components/styledMdText/StyledMdText'
import { content } from "../../../data"
import { Link } from 'react-router-dom'

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const LandingSection = () => {

  const imageRef = useRef();
  const leftContentTag = useRef();
  const leftContentHeading = useRef();
  const leftContentText = useRef();
  const leftContentSocials = useRef();
  const rightContent = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(imageRef.current, {
        duration: 2,
        delay: 1.25,
        opacity: 0,
        y: 50,
        ease: "power2.out",
      });

      //Animation No.2
      gsap.from(leftContentTag.current, {
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.3
      gsap.from(leftContentHeading.current, {
        duration: 2,
        delay: 0.25,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.4
      gsap.from(leftContentText.current, {
        delay: 0.5,
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.5
      gsap.from(leftContentSocials.current, {
        delay: 0.75,
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.6
      gsap.from(rightContent.current, {
        delay: 1,
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <section id='homepage' style={{
      background: `url("/assets/images/hero-sec-bg.png")`,
      backgroundSize: "cover"
    }}>
      <div className='flex justify-center h-full pt-[30%] md:pt-[15%] lg:pt-[12.5%]'>
        <div className='w-[90%] flex lg:flex-row flex-col relative'>
          <div className='z-20 w-full lg:w-[35%] flex flex-col justify-around h-full'>
            <div className='flex flex-col gap-1'>
              <div className='leading-none' ref={leftContentTag}>
                <StyledH6Heading fontColor="text-white !font-gilroy-extrabold !text-[14px]">CHAD Coin</StyledH6Heading>
              </div>
              <div ref={leftContentHeading} className='flex flex-col leading-tight'>
                <div>
                  <StyledH1Heading regularContent={"We are"} regularFontColor={"text-white"} boldColor={"text-orange"} boldContent={"Giga Chad"} />
                </div>
                <div>
                  <StyledH1Heading regularContent={"We are"} regularFontColor={"text-white"} boldColor={"text-white"} boldContent={"The Sigma"} />
                </div>
              </div>
              <div className='pt-2' ref={leftContentText}>
                <StyledLgText fontColor="text-white">With the strength of GIGA CHAD, we're not just trading tokens; we're lifting entire communities, turning digital currency into real-world change</StyledLgText>
              </div>
            </div>
            <div ref={leftContentSocials}>
              <div className="flex flex-col gap-3 pt-5 lg:pt-0">
                <div className="">
                  <StyledMdText fontColor="text-white">Find us on</StyledMdText>
                </div>
                <div className="flex gap-3">
                  {content?.layout?.footer?.map((item, index) => (
                    <Link to={item.link} target='__blank' className="uppercase duration-500 cursor-pointer hover:scale-110 " key={index}>
                      <img src={item?.imgURL} alt="Social Icons" />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div ref={imageRef} className='flex justify-center lg:items-end pt-10 lg:pt-0 lg:w-[40%]'>
            <img src="/assets/images/hero-sec-chad-boy.png" alt='Big Chad Boy' />
          </div>
          <div ref={rightContent} className='lg:w-[25%] py-10 lg:py-0 flex justify-center items-end h-[90%]'>
            <div className='flex flex-col gap-1 leading-none'>
              <div>
                <img src='/assets/images/hero-sec-coins.png' alt='Coins' />
              </div>
              <div>
                <h4 className='font-normal text-white font-gilroy-regular text-h4'>First Ever</h4>
              </div>
              <div>
                <h4 className='text-white font-gilroy-extrabold text-h4'>Sigma Coin</h4>
              </div>
              <div className="pt-1">
                <StyledMdText fontColor="text-white">Made with <span className="font-extrabold text-orange">Purpose</span></StyledMdText>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingSection
