import React, { useLayoutEffect, useRef } from 'react'
import StyledH6Heading from '../../../common/components/styledH6Heading/StyledH6Heading'
import StyledH1Heading from '../../../common/components/styledH1Heading/StyledH1Heading'
import { Success } from "../../../components/toast/Success";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const RevealSection = () => {

    const imgBox = useRef();
    const headingBox = useRef();

    function copyAddress() {
        var addressField = document.getElementById("addressField");
        addressField.select();
        document.execCommand("copy");
        Success({ message: "Copied", autoClose: true });
    }

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {

            //Animation No.1
            gsap.from(headingBox.current, {
                duration: 1,
                opacity: 0,
                x: -50, // move from left to right
                ease: "power2.out",
                scrollTrigger: {
                    trigger: headingBox.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=20%", // Adjust the start position as needed
                    // markers: true,
                },
            });

            //Animation No.2
            gsap.from(imgBox.current, {
                duration: 1,
                opacity: 0,
                y: 50, // move from left to right
                ease: "power2.out",
                scrollTrigger: {
                    trigger: headingBox.current,
                    toggleActions: "play none none reverse",
                    start: "top bottom-=20%", // Adjust the start position as needed
                    // markers: true,
                },
            });

        });
        return () => ctx.revert(); // cleanup
    }, []);

    return (
        <div className='flex justify-center pt-20'>
            <div className='w-[90%] flex lg:flex-row flex-col'>
                <div className='lg:w-[60%] lg:pl-32 !h-[75%] flex items-center'>
                    <div ref={headingBox} className='flex flex-col justify-center gap-2'>
                        <div className='leading-none'>
                            <StyledH6Heading fontColor="text-white !text-[14px] !font-gilroy-extrabold">Revealing</StyledH6Heading>
                        </div>
                        <div className='lg:w-[65%] leading-none'>
                            <StyledH1Heading regularContent={"The Alpha"} regularFontColor={"text-white"} boldColor={"text-orange"} boldContent={"Contract Address"} />
                        </div>
                        <div className='w-full lg:w-[80%] relative pt-3'>
                            <input className='pl-4 cursor-not-allowed pr-10 text-md py-2 border rounded-md border-orange text-white flex-wrap bg-[#FF6D280D] bg-opacity-5 w-full' type="text" id="addressField" defaultValue="0x43b28B72315CC9c17bcfE47273237b45bCca3EC3" readOnly />
                            <button>
                                <img onClick={copyAddress} src='/assets/icons/copy.png' className="absolute top-0 py-2 mt-3 mr-1 cursor-pointer right-2" alt="Copy Button" />
                            </button>
                        </div>
                    </div>
                </div>
                <div ref={imgBox} className='lg:w-[40%] animate-pulse flex justify-center'><img src='/assets/images/reveal-sec-coins.png' alt='Reveal Section Coins' /></div>
            </div>
        </div>
    )
}

export default RevealSection
