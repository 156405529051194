export const content = {
    layout: {
        header: [
            {
                title: "Home",
                link: "#homepage"
            },
            {
                title: "About",
                link: "#about"
            },
            {
                title: "Protocol",
                link: "#protocol"
            },
            {
                title: "Tokenomics",
                link: "#tokenomics"
            },
            {
                title: "Roadmap",
                link: "#roadmap"
            },
            {
                title: "FAQs",
                link: "#faqs"
            },
            {
                title: "Contact",
                link: "#contact"
            },
        ],
        footer: [
            {
                imgURL: "/assets/icons/discord.png",
                link: "https://discord.gg/tzRS33MU"
            },
            {
                imgURL: "/assets/icons/twitter.png",
                link: "https://twitter.com/giga_chad_token"
            },
            {
                imgURL: "/assets/icons/telegram.png",
                link: "https://t.me/gigachaderc"
            },
        ],
    },
    marquee: [
        {
            content: "Buy Giga Chad - And Be A Chad"
        },
    ],
    community: [
        {
            imgUrl: "/assets/images/community-1.png"
        },
        {
            imgUrl: "/assets/images/community-2.png"
        },
        {
            imgUrl: "/assets/images/community-3.png"
        },
        {
            imgUrl: "/assets/images/community-4.png"
        },
    ],
    faq: [
        {
            question: "What is Giga Chad Coin?",
            answer: "GIGA CHAD Coin is a cryptocurrency that leverages the blockchain to support charitable causes, and more, with a unique transaction tax system designed to fuel its mission."
        },
        {
            question: "How to fix insufficient output on pancake swap?",
            answer: "Increase the slippage to 1%, 5%, 10%, etc until it allows you to make the trade. Sometimes it will say your trade could be front-runned, but there is only a 1% chance of this."
        },
        {
            question: "What is the transaction tax on GIGA CHAD Coins, and how is it used?",
            answer: "There's a 5% transaction tax on GIGA CHAD Coins, divided among charity, marketing, and liquidity. This tax is reduced to 0% for transactions on centralized exchanges, thanks to a whitelist protocol."
        },
        {
            question: "Can the transaction tax ever change?",
            answer: "The transaction tax is fixed at 5% but can be adjusted down to 0% under specific conditions, such as trading on whitelisted centralized exchanges."
        },
        {
            question: "What happens to unused GIGA CHAD Coins?",
            answer: "Any GIGA CHAD Coins not allocated as intended (for team, charities, marketing, or centralized exchanges) will be burned, aligning with our commitment to transparency and value."
        },
        {
            question: "Where can I find more information or support?",
            answer: "For more details or assistance, visit our official website and join our community on social media platforms. Our team is always ready to help with any questions you may have."
        },
    ],
    protocol: [
        {
            head: "Locked Liquidity",
            body: "100 percent of the initial LP tokens are locked."
        },
        {
            head: "Charity Wallet",
            body: "We have a designated charity wallet that receives distributions that are used to support our charity partners."
        },
    ],
    howToBuy: [
        {
            title: "Create a Wallet:",
            answer: "Begin by setting up a cryptocurrency wallet that supports Binance Smart Chain (BSC) transactions, such as MetaMask or Trust Wallet."
        },
        {
            title: "Add Binance Smart Chain:",
            answer: "Ensure your wallet is configured to operate on the Binance Smart Chain network. This may involve adding the network manually in your wallet's settings."
        },
        {
            title: "Purchase BNB:",
            answer: "Buy Binance Coin (BNB) through your wallet (directly on the wallet by clicking buy button) or on an exchange. BNB is required to trade for GIGA CHAD Coins and pay for transaction fees on PancakeSwap."
        },
        {
            title: "Send BNB to Your Wallet:",
            answer: "If you purchased BNB on an exchange, transfer it to your BSC-compatible wallet using your wallet's BSC address."
        },
        {
            title: "Connect to PancakeSwap:",
            answer: "Visit PancakeSwap pool and connect your wallet by selecting the 'Connect Wallet' button and following the prompts to authorize the connection."
        },
        {
            title: "Swap BNB for GIGA CHAD Coins:",
            answer: "Once connected, select the 'Swap' feature, choose BNB as the input currency, and GIGA CHAD Coin as the output currency. Enter the amount of BNB you wish to swap, review the transaction details, and confirm the swap. Make sure to have a little extra BNB for transaction fees. If transaction fail try to increase slippage to 5% or 10%."
        },
    ],
    roadmap: {
        2024: [
            {
                title: "Q1 2024",
                answer: "Conceptualization of GIGA CHAD Coin, inspired by the viral strength and altruistic spirit of the GIGA CHAD meme. Fair launch on Pancake Swap, setting a precedent for accessibility and equity in the crypto world.",
            },
            {
                title: "Q2 2024",
                answer: "Launch of major marketing campaigns to raise awareness and grow the GIGA CHAD community. Partnership with key influencers and organizations to amplify our reach and impact.",
            },
            {
                title: "Q3 2024",
                answer: "Enhancement of the GIGA CHAD platform to include new features for community engagement and governance.",
            },
            {
                title: "Q4 2024",
                answer: "Expansion into additional decentralized and centralized exchanges to improve liquidity and accessibility.",
            },
        ],
        2025: [
            {
                title: "Q1 2025",
                answer: "Introduction of GIGACHAD NFTs, integrating unique digital collectibles with our ecosystem for charitable fundraising.",
            },
            {
                title: "Q2 2025",
                answer: "Launch of GIGA CHADDAO (Decentralized Autonomous Organization), empowering the community with decision-making authority.",
            },
            {
                title: "Q3 2025",
                answer: "Development and beta testing of GIGA CHAD DeFi protocols, offering innovative financial tools and services.",
            },
            {
                title: "Q4 2025 & Beyond",
                answer: "Continuous exploration of emerging technologies and opportunities for impact, ensuring GIGA CHAD Coin remains at the forefront of crypto philanthropy.",
            },
        ],
    },
}