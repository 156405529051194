import React from 'react'
import StyledXlText from "../../common/components/styledXlText/StyledXlText"
import StyledLgText from "../../common/components/styledLgText/StyledLgText"
import Button from "../../components/button/Button"

const NotFoundPage = () => {
  return (
    <div className='h-[100vh] lg:h-[95vh] w-full flex justify-center'>
      <div className='flex flex-col w-[80%] items-center justify-center h-full gap-2'>
        <div>
          <img src='/assets/images/404.png' alt='404' />
        </div>
        <div className='pt-5 text-center'>
          <StyledXlText fontColor="text-white !text-[20px]">Page you are looking for is currently unavailable</StyledXlText>
        </div>
        <div className='text-center'>
          <StyledLgText fontColor="text-white">But don’t worry, you can go back to home by clicking the button below</StyledLgText>
        </div>
        <div className='pt-5 w-[70%] md:w-[50%] lg:w-[20%]'>
          <Button link="/" Content="Back To Home" />
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
