import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Success = ({ message, autoClose }) => {
  
    toast.success(
      <p className="w-[100%] font-gilroy-regular">
        {message}
      </p>,

      {
        icon: () => <img src="/assets/icons/Giga Coin 3.png" alt="success img" />,
        position: "top-center",
        closeButton: (
          <button
            style={{
              color: "#FF003D",
              backgroundColor: "#0c0c0c",
              border: "none",
            }}
            onClick={() => toast.dismiss()}
            className="absolute top-1 -right-2"
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5563 2.65685C11.4319 -0.46754 6.36695 -0.46754 3.24256 2.65685C0.118166 5.78125 0.118166 10.8462 3.24256 13.9706C6.36695 17.095 11.4319 17.095 14.5563 13.9706C17.6807 10.8462 17.6807 5.78125 14.5563 2.65685ZM11.3884 11.7078L8.89941 9.2188L6.4104 11.7078C6.29037 11.8278 6.12759 11.8953 5.95785 11.8953C5.78811 11.8953 5.62533 11.8278 5.5053 11.7078C5.38528 11.5878 5.31785 11.425 5.31785 11.2553C5.31785 11.0855 5.38528 10.9227 5.5053 10.8027L7.99432 8.31371L5.5053 5.82469C5.38528 5.70467 5.31785 5.54188 5.31785 5.37214C5.31785 5.20241 5.38528 5.03962 5.5053 4.9196C5.62532 4.79957 5.78811 4.73214 5.95785 4.73214C6.12759 4.73214 6.29038 4.79957 6.4104 4.9196L8.89941 7.40861L11.3884 4.9196C11.5085 4.79957 11.6712 4.73214 11.841 4.73214C12.0107 4.73214 12.1735 4.79957 12.2935 4.9196C12.4136 5.03962 12.481 5.20241 12.481 5.37214C12.481 5.54188 12.4135 5.70467 12.2935 5.82469L9.80451 8.31371L12.2935 10.8027C12.4136 10.9227 12.481 11.0855 12.481 11.2553C12.481 11.425 12.4135 11.5878 12.2935 11.7078C12.1735 11.8278 12.0107 11.8953 11.841 11.8953C11.6712 11.8953 11.5085 11.8278 11.3884 11.7078Z"
                fill="#FFC107"
              />
            </svg>
          </button>
        ),
        style: {
          color: "#fff",
          fontSize: "16px",
          backgroundColor: "#0c0c0c",
          border: "2px solid #fd8d14",
          borderRadius: "0.7rem",
          width:'100%'
        },
      }
    );


};

