import React, { useLayoutEffect, useRef } from "react";
import StyledH2Heading from "../../../common/components/styledH2Heading/StyledH2Heading";
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import { content } from "../../../data";
import ProtocolBox from "../../../components/protocolBox/ProtocolBox";
import AnimatedLink from "../../../components/animatedLinks/AnimatedLink";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Success } from "../../../components/toast/Success";
// import { Success } from "../../../components/toast/Success";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const CoinProtocolSection = () => {

  const imgBox = useRef();
  const contentLeftBox = useRef();
  const contentRightBox = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(contentLeftBox.current, {
        duration: 1,
        opacity: 0,
        x: -50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: contentLeftBox.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=30%", // Adjust the start position as needed
          // markers: true,
        },
      });

      //Animation No.2
      gsap.from(contentRightBox.current, {
        duration: 1,
        opacity: 0,
        x: 50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: contentRightBox.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=30%", // Adjust the start position as needed
          // markers: true,
        },
      });

      //Animation No.3
      gsap.from(imgBox.current, {
        duration: 2,
        opacity: 0,
        y: 50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: contentLeftBox.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=30%", // Adjust the start position as needed
          // markers: true,
        },
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  // const handleToastShow = () => {
  //   Success({ message: "This Feature is Coming Soon", autoClose: true });
  // }

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        Success({ message: "Copied", autoClose: true });
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  }

  return (
    <section id="protocol" className="flex items-center justify-center w-full h-full pt-20">
      <div className="w-[90%] flex gap-3 lg:flex-row flex-col justify-between items-center ">
        <div ref={contentLeftBox} className=" md:w-[50%] lg:w-[30%] flex flex-col gap-4 justify-center">
          <StyledH2Heading
            regularFontColor={"text-white"}
            regularContent={"Giga Chad "}
            boldColor={"text-orange"}
            boldContent={"Coin Protocol"}
          />
          <StyledMdText
            fontColor={"text-white"}
            children={
              "The GIGA CHAD Coin operates on a fixed 5% transaction tax, allocated across charity, marketing, and liquidity to support our mission and growth, this way each transaction will directly support the mission. This tax is designed with flexibility to reduce to 0% when trading on centralized exchanges, ensuring seamless transactions. Centralized exchanges are whitelisted to remove them from this tax, aligning with our goal to broaden accessibility and impact"
            }
          />
          <div className="flex flex-col items-start justify-start gap-2">
            <AnimatedLink href="https://mudra.website/?certificate=yes&type=0&lp=0xf838581b751cd76a8b745c21fbc6860ade08eacb" content={'Locked Liquidity'} />
            <AnimatedLink onClick={() => copyTextToClipboard("0x7313c89C812ccDB0b5549a14acfC1fd9A88C5b6A")} content={'Charity Wallet'} />
          </div>
        </div>
        <div ref={imgBox} className=" flex lg:w-[45%] py-8 justify-center items-center">
          <img src="/assets/images/coinprotocol.png" alt="Coin Protocol img" />
        </div>
        <div ref={contentRightBox} className="h-[100%] md:w-[50%] flex items-end lg:self-end lg:w-[30%]">
          <div className="flex flex-col gap-4 ">
            {content?.protocol?.map((items, index) =>
              <div key={index} className="flex flex-col items-center justify-end" >
                <ProtocolBox content={items?.head} child={items?.body} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoinProtocolSection;
